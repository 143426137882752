import React from "react";

// reactstrap components
import {
  Card,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

// core components

const items = [
  {
    src: require("../../../src/assets/img/lathe.jpg"),
    altText: "Somewhere",
  },
  {
      src: require("../../../src/assets/img/drilling.jpg"),
    //src: require("../../../src/assets/img/drilling.png"),
    altText: "Somewhere else",
  },
  {
    src: require("../../../src/assets/img/cutting-machine.jpg"),
    altText: "Here it is",
},
{
  src: require("../../../src/assets/img/shaping.jpg"),
  altText: "Here it is",
},
{
  src: require("../../../src/assets/img/milling.jpg"),
  altText: "Here it is",
}
];

function SectionCarousel() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div className="col-md-12">
        <div className="row">
            <div className="col-md-6 t-left">
                <div className="row">
                    <div className="col-md-10 m-auto">
                        <h2>Buy your <span>industrial machineries</span> at one place</h2>
                    </div>
                </div>
            </div>
            <div className="col-md-6 t-right">
                <div className="carousel-wrap">
                    <Card className="page-carousel m-auto">
                       <Carousel
                         activeIndex={activeIndex}
                         next={next}
                         previous={previous}
                       >
                         <CarouselIndicators
                           items={items}
                           activeIndex={activeIndex}
                           onClickHandler={goToIndex}
                         />
                         {items.map(item => {
                           return (
                             <CarouselItem
                               onExiting={onExiting}
                               onExited={onExited}
                               key={item.src}
                             >
                               <img src={item.src} alt={item.altText} />
                             </CarouselItem>
                           );
                         })}
                         <a
                           className="left carousel-control carousel-control-prev"
                           data-slide="prev"
                           href="#pablo"
                           onClick={e => {
                             e.preventDefault();
                             previous();
                           }}
                           role="button"
                         >
                           <span className="fa fa-angle-left" />
                           <span className="sr-only">Previous</span>
                         </a>
                         <a
                           className="right carousel-control carousel-control-next"
                           data-slide="next"
                           href="#pablo"
                           onClick={e => {
                             e.preventDefault();
                             next();
                           }}
                           role="button"
                         >
                           <span className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </a>
                       </Carousel>
                     </Card>
                </div>

             </div>

        </div>
             {/* <Card className="page-carousel">
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                >
                  <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <CarouselCaption
                          captionText={item.caption}
                          captionHeader=""
                        />
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="left carousel-control carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="right carousel-control carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </a>
                </Carousel>
              </Card> */}




      </div>{" "}
    </>
  );
}

export default SectionCarousel;

import React from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import Routes from '../src/routes/Routes';

function App() {
  return (
    <div>
       <BrowserRouter basename="/">
         <Route component={Routes}/>
       </BrowserRouter>
    </div>
  );
}

export default App;

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

//styles
import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/paper-kit.css";
import "../src/assets/css/style.css";

//import Icon from 'react-native-vector-icons/dist/FontAwesome';



ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import Layout from '../../pages/Layout';

const AboutUs = () => {
    return(
        <Layout>
            <div className="container pb-5">
                <div className="row welcome">
                    <div className="col-md-7 left">
                        <h2><span>About</span> Veerakumar</h2>
                        <p>  Veerakumar Engineering Company was started in 1982. The business is owned by family members of Lion K. Dhanuskodi and his brother Lion K. Natarajan.
                        </p>
                        <p>From 1982 the firm was doing job work for other companies. We started used machine business in 1985. The continues of our development, We started the Veerakumar industrial machineries unit, We included new machines, used machines, reconditioning machineries and machines spares sales and services as well. We are the leading dealer of Lathe, Drilling and Cutting machineries in Coimbatore.</p>
                    </div>
                    <div className="col-md-5 right">
                        <div className="row">
                            <div className="col-md-10 pt-4">
                                <div className="photo-frame text-center">
                                    <img src="/images/vk-parents.jpg" className="img-fluid" alt="Our Company" />
                                    <p> "I thank to my parents for being so"</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row welcome">
                <div className="col-md-5 right">
                    <div className="row">
                        <div className="col-md-10 pt-4">
                            <div className="photo-frame text-center">
                                <img src="/images/vk-nataraj.jpg" className="img-fluid" alt="Our Company" />
                                <p> Lion K. Natarajan</p>
                            </div>
                        </div>
                    </div>
                </div>
                    <div className="col-md-7 left">
                    <h3  className="pt-5">Managing Director</h3>
<p className="pt-5">Lion, K. Natarajan is the Director of this company and president of machineries traders association. He has more than 30 yrs experience in this field. Continues of his hard work and trust of his customers and associates we are growing in the field of machinery industries.</p>
<p>He believe of his success with the support of his brother Lion. K. Dhanuskodi. He interested and involved in lions club & social activities also. </p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AboutUs;

import React from "react";
import { NavLink } from "react-router-dom";
import { Row, Container, Col } from "reactstrap";

function Footer() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
            <Col md="4" className="ft-col">
                <img src="/images/vk-logo.png" width="120" alt="Veerakumar" />
                <p>Veerakumar Industrial Machineries is a leading dealer & supplier of Lathe, Drilling machineries sales in Coimbatore. This is the right place  is a place, where you can Buy/Sell a used machinery with high quality and reasonable price. </p>
            </Col>
            <Col md="3" className="ft-col">
                <h3>Quick Links</h3>
                <ul>
                    <li><NavLink exact to="/">Home</NavLink></li>
                    <li><NavLink to="/about-us">About Us</NavLink></li>
                    <li><NavLink to="/products">Products & Services</NavLink></li>
                    <li><NavLink to="/contact">Contact Us</NavLink></li>
                </ul>
            </Col>
            <Col md="5" className="ft-col ft-con-cal">
                <h3>Contact Us</h3>
                <div className="ic-contact">
                    <p>+91 98422 21182</p>
                    <p>+91 90470 13692, +91 90470 13693</p>
                </div>
                <div className="ic-mail">
                    <p><a href="mailto:vknatarajan2010@gmail.com">vknatarajan2010@gmail.com</a></p>
                </div>
                <div className="ic-address">
                    <p>No. 481/2, Ellaithottam Road, V.O.C. Colony East,</p>
                    <p>Peelamedu, Coimbatore - 641 004</p>
                </div>
            </Col>
        </Row>
      </Container>
      <div className="ft-border p-0">
          <Container>
            <Col md='12'>
        <Row>
            <Col md="6" className="p-0">
                <nav className="footer-nav">
                  © Copy rights reserved  {new Date().getFullYear()}, Veerakumar Industrial Machineries.
                </nav>
            </Col>
            <Col md="6" className="text-right p-0">
                <div className="credits ml-auto">
                  <span className="copyright">
                    Made with {" "}
                    <i className="fa fa-heart heart" /> by <a href="https://apppixel.com" target="/_blank">AppPixel</a>
                  </span>
                </div>
            </Col>
        </Row>
            </Col>
          </Container>
      </div>
    </footer>
  );
}

export default Footer;

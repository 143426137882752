import React, { useState } from "react";
import Slider from "react-slick";
import axios from 'axios';
import toaster from 'toasted-notes'
import 'toasted-notes/src/styles.css';

// core components
import Layout from '../../pages/Layout';
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroup,
  Row,
} from "reactstrap";

function LandingPage() {
  const initialFormState = {name: '', number: '', error : {}};
  const [data, setData] = useState(initialFormState);

  const loader = {submitLoading:false};
  const [load, setLoad] = useState(loader);

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5001,
    autoplaySpeed: 10,
    cssEase: "linear"
  };

  const handleChange = (event) => {
   const {name, value} = event.target;
   setData({...data, [name]:value});
 }

 const handleSubmit = (event) => {
     event.preventDefault();
     console.log('handleSubmit called');
     let errors = validateData(data);
     setData({...data, error:errors});
     if(!data.name || !data.number){
       return;
     }else if(data.number.length < 10 ){
       return;
     }
     setLoad({...load, submitLoading:true});
     axios.post('http://localhost:8001/api/mail/sendHomemail',{data})
       .then((response) => {
         toastMessage();
       })
       .catch((error) => {
         console.log('error block called',error);
         setLoad({...load, submitLoading:false});
       })
       setData(initialFormState);
 }

 const toastMessage = () => {
   toaster.notify(<div style={{color:'green'}}>
                Your query submitted successfully ! Our team will contact you shortly
              </div>,{duration:2000});
   setLoad({...load, submitLoading:false});
 }

 const validateData = (data) => {
   const errors = {};
   if(data.name.length <= 0){
     errors.name = "Name field cannot be empty"
   }
   if(data.number === ''){
     errors.number = "Mobile number field cannot be empty";
   }else if(data.number.toString().trim().length !== 10){
     errors.number ='Mobile number should be 10 digits';
   }
   return errors;
 }

  return (
    <Layout>
      <div className="main">
        <div className="container">
            <div className="row welcome">
                <div className="col-md-5 right">
                    <div className="photo-frame">
                        <img src="/images/vk-company-1.jpg" className="img-fluid" alt="Our Company" />
                    </div>
                </div>
                <div className="col-md-7 left">
                    <h2><span>Welcome to</span> Veerakumar</h2>
                    <p className="px-5">	Veerakumar Industrial Machineries is located in Coimbatore. We are the leading dealer of Lathe and Drilling Machineries in Coimbatore.
	Our company was started in 1982 as Veerakumar Engineeing Company. </p>
                    <p className="px-5">The business is owned by family members of Lion K. Dhanuskodi and his brother Lion K. Natarajan. In 1982 the firm was doing job work for other companies. We started used machine business in 1985...</p>
                    <a href="/about-us" className="btn btn-primary float-right">Read More</a>
                </div>
            </div>
        </div>
        {/*<div className="consulting-form">
            <div className="container">
                <h5>Do you want to <span>Buy/Sale</span> a <span>New/Old</span> Machineries </h5>
                <h3>Contact for Free Consulting </h3>
                <div className="m-auto col-md-5">
                    <div className="form-wrap">
                        <div className="row">
                            <input className="form-control col-md-5" placeholder="Enter Your Name" />
                            <input className="form-control col-md-5" placeholder="Enter Your Mobile No" />
                            <button className="col-md-2" >Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>*/}

        <div className="consulting-form">
            <div className="container">
                {load.submitLoading &&
                <div className="loading">
                </div>
                }
                <h5>Do you want to <span>Buy and Sale</span> a <span>New or Old</span> Machines </h5>
                <h3>Contact for Free Consulting </h3>
                  <Row>
                {/*<div class="loader">Loading...</div> */}
                  {/*<div className="m-auto col-md-5">
                      <div className="form-wrap">
                          <div className="row">
                              <input className="form-control col-md-5" placeholder="Enter Your Name" />
                              <input className="form-control col-md-5" placeholder="Enter Your Mobile No" />
                              <button className="col-md-2" >Submit</button>
                          </div>
                      </div>
                  </div>*/}

                  <div className="m-auto col-md-5">
                    <div className="form-wrap">
                        <div className="row">
                            <div className="col-md-5 p-0">
                                <InputGroup>
                                  <InputGroupAddon addonType="prepend">
                                  </InputGroupAddon>
                                  <Input name="name" placeholder="Enter Your Name" type="text" value={data.name} onChange={handleChange}/>
                                </InputGroup>
                                {data.error.name && <span className="error">{data.error.name}</span>}
                            </div>
                            <div className="col-md-5 p-0">
                                <InputGroup>
                                  <InputGroupAddon addonType="prepend">
                                  </InputGroupAddon>
                                  <Input name="number" placeholder="Enter Your Mobile Number" type="text" value={data.number} onChange={handleChange}/>
                                </InputGroup>
                                {data.error.number && <span className="error">{data.error.number}</span>}
                            </div>
                            <div className="col-md-2 p-0">
                            <Button className="" onClick={handleSubmit}>Submit</Button>
                            </div>
                        </div>
                    </div>

                  </div>
                  {/*
                    <Col md="6">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                        </InputGroupAddon>
                        <Input name="name" placeholder="Enter Your Name" type="text" value={data.name} onChange={handleChange}/>
                      </InputGroup>
                      {data.error.name && <span style={{color:'red'}}>{data.error.name}</span>}
                    </Col>

                    <Col md="6">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                        </InputGroupAddon>
                        <Input name="number" placeholder="Enter Your Mobile Number" type="number" value={data.number} onChange={handleChange}/>
                      </InputGroup>
                      {data.error.number && <span style={{color:'red'}}>{data.error.number}</span>}
                    </Col>
                    </Row>

                    <div style={{marginTop:'50px'}}>
                    <Col className="ml-auto mr-auto" md="4">
                      <Button className="btn-fill" color="danger" size="lg" onClick={handleSubmit}>
                        Send Message
                      </Button>
                    </Col>
                    </div>
                    */}
                        </Row>
            </div>
        </div>
        <div className="ourservice">
            <div className="container">
                <h2 className="text-center"><span>What</span> We Do</h2>
                <div className="col-md-10 m-auto">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="col">
                                <div className="m-auto serv-icon">
                                    <img src="/images/machine-sale.png" width="64" alt="Machineries Sales" />
                                </div>
                                <h4>Machineries Sales</h4>
                                <p> Buy New & Old industrial machineries with affordable price... </p>
                                <a href="/products" className="btn btn-primary">Read More</a>
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="col">
                            <div className="m-auto serv-icon">
                                <img src="/images/machine-reconditioning.png" width="64" alt="Machineries Reconditioning" />
                            </div>
                            <h4>Machineries Services</h4>
                            <p>Bed Grinding, Bearing Seating & Reconditioning...</p>
                            <a href="/products" className="btn btn-primary">Read More</a>
                        </div>
                        </div>
                        <div className="col-md-4">
                        <div className="col">
                            <div className="m-auto serv-icon">
                                <img src="/images/machine-spare-sale.png" width="64" alt="Machineries Spare Sales" />
                            </div>
                            <h4>Machineries Spares</h4>
                            <p>Lathe, Drilling, Cutting machines spares available..</p>
                            <a href="/products" className="btn btn-primary">Read More</a>
                        </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div className="our-customer">
            <div className="container">
                <h2><span>Our</span> Customers</h2>
        <Slider {...settings}>
          <div className="img-wrap">
            <img src="/images/vk-marks.png" className="img-fluid" alt="Marks" />
          </div>
          <div className="img-wrap">
            <img src="/images/vk-deccan.png" className="img-fluid" alt="Marks" />
          </div>
          <div className="img-wrap">
            <img src="/images/vk-mahendra.png" className="img-fluid" alt="Marks" />
          </div>
          <div className="img-wrap">
            <img src="/images/vk-psr.png" className="img-fluid" alt="Marks" />
          </div>
          <div className="img-wrap">
            <img src="/images/vk-psn.png" className="img-fluid" alt="Marks" />
          </div>
          <div className="img-wrap">
            <img src="/images/vk-murugappa.png" className="img-fluid" alt="Marks" />
          </div>
          <div className="img-wrap">
            <img src="/images/vk-met.png" className="img-fluid" alt="Marks" />
          </div>
          <div className="img-wrap">
            <img src="/images/vk-vivekanandha.png" className="img-fluid" alt="Marks" />
          </div>
          <div className="img-wrap">
            <img src="/images/vk-ssm.png" className="img-fluid" alt="Marks" />
          </div>
          <div className="img-wrap">
            <img src="/images/vk-hazi.png" className="img-fluid" alt="Marks" />
          </div>
          <div className="img-wrap">
            <img src="/images/vk-kamaraj.png" className="img-fluid" alt="Marks" />
          </div>
          <div className="img-wrap">
            <img src="/images/vk-kongu.png" className="img-fluid" alt="Marks" />
          </div>
        </Slider>
        </div>


        </div>
      </div>
    </Layout>
  );
}

export default LandingPage;

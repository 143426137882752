import React from 'react';
import Layout from '../../pages/Layout';

const Products = () => {
    return(
        <Layout>
            <div className="container pb-5">
                <div className="row welcome">
                    <div className="col-md-6 left">
                        <h2><span className="single">Products</span> & Services</h2>
                        <p className="pt-3 pr-5">
                        Veerakumar Industrial Machineries is a Dealer for leading Lathe Manufacturers ACME, Minar and Pargco. We supply the following kinds of machineries.
                        </p>
                        <h3>New Machineries Sales</h3>
                        <ul>
                            <li>Heavy Duty Lathe</li>
                            <li>Light Duty Lathe</li>
                            <li>Drilling Machine</li>
                            <li>Power Press</li>
                            <li>Slotting Machine</li>
                            <li>Hydrolic Press</li>
                        </ul>
                    </div>
                    <div className="col-md-6 right">
                        <div className="row">
                            <div className="col-md-10 pt-4">
                                <div className="photo-frame text-center">
                                    <img src="/images/vk-new-machines.jpg" className="img-fluid" alt="Our Company" />
                                </div>
                            </div>.
                        </div>

                    </div>
                </div>
                <div className="row welcome">
                    <div className="col-md-6 right">
                        <div className="row">
                            <div className="col-md-10 pt-4">
                                <div className="photo-frame text-center">
                                    <img src="/images/vk-old-machines.jpg" className="img-fluid" alt="Our Company" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 left mt-5">
                    <h3>Old Machineries Buy & Sales</h3>
                    <p className="pt-3">Veerakumar Industrial Machineries is a place, where you can Buy/Sell a used machinery with high quality and reasonable price. Also, we are assisting the exchange process of used machineries.</p>
                    <div className="row">
                        <div className="col-md-6">
                            <ul>
                                <li>Lathe Machines</li>
                                <li>Milling Machines</li>
                                <li>Drilling machines</li>
                                <li>Power Press</li>
                                <li>Shaping Machines</li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <ul>
                                <li>Surface Grinding Machines</li>
                                <li>Cutting Machines</li>
                                <li>Sealing Machines</li>
                                <li>Pending Machines</li>
                                <li>Other Industrial Machines</li>
                            </ul>
                        </div>
                    </div>


                    </div>
                </div>
                <div className="row welcome">
                    <div className="col-md-6 left pt-5">
                        <h3>Machineries Services & Spares</h3>
                        <p className="pt-3">We are doing lathe and drilling machine services and reconditioning with high quality.</p>
                        <ul>
                            <li>Bed Grinding</li>
                            <li>Bearing Seating</li>
                            <li>Reconditioning</li>
                            <li>4 & 3 jaw chuks</li>
                            <li>Motors</li>
                            <li>Lathe & Drilling Machineries Spares are available</li>
                        </ul>
                    </div>
                    <div className="col-md-6 right">
                        <div className="row">
                            <div className="col-md-10 pt-4">
                                <div className="photo-frame text-center">
                                    <img src="/images/vk-machine-spares.jpg" className="img-fluid" alt="Our Company" />

                                </div>
                            </div>.
                        </div>

                    </div>
                </div>
                <div className="row welcome">
                    <div className="col-md-6 right">
                        <div className="row">
                            <div className="col-md-10 pt-4">
                                <div className="photo-frame text-center">
                                    <img src="/images/vk-award.jpg" className="img-fluid" alt="Our Company" />
                                    <p>"Receiving award from GPT Perundurai for best training"</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 left mt-5">
                    <h3>Industrial Training for Colleges</h3>
                    <p className="pt-3">We are also provided the training for college students who required the training in machineries.</p>
                    <p>Leading colleges in tamilnadu are approch us for the training and we provided training with experience persons and high quality.</p>
                    </div>
                </div>


            </div>

        </Layout>
    )
}

export default Products;

import React from "react";
import { NavLink } from "react-router-dom";

// nodejs library that concatenates strings
import classnames from "classnames";

// reactstrap components
import {
  Collapse,NavbarBrand,Navbar, NavItem,Nav, Container, Row, Col} from "reactstrap";

function NavigationHeader() {

  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (

    <Navbar
      className={classnames("fixed-top", navbarColor)}
      expand="lg"
    >

    <div className="header-top">
         <Container className="text-right">
            <Row>
                <Col md="6"></Col>
                <Col md="6" className="text-right">
                    <span><img src="/images/ic-phone.png" alt="98422 21182" width="28" /><a href="#/">Call 98422 21182</a></span>
                    <span><img src="/images/ic-mail.png" alt="vknatarajan2010@gmail.com" width="41" /><a href="mailto:vknatarajan2010@gmail.com">vknatarajan2010@gmail.com</a></span>
                </Col>
            </Row>
         </Container>
    </div>
      <Container className="nav-cont">
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            to="/index"
          >
            <div className="logo"><img src="/images/logo.png" alt="Veerakumar" width="220" /></div>

          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
        <Nav navbar>
          <NavItem><NavLink exact to="/">Home</NavLink></NavItem>
          <NavItem><NavLink to="/about-us">About Us</NavLink></NavItem>
          <NavItem><NavLink to="/products">Products & Services</NavLink></NavItem>
          <NavItem><NavLink to="/contact">Contact Us</NavLink></NavItem>
        </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationHeader;

import React, { useState } from 'react';
import {
    Button,
    Form,
    Input,
    InputGroup,
    Container,
    Row,
    Col
} from "reactstrap";
import Layout from '../../pages/Layout';
import axios from 'axios';
import Validator from 'validator';
import toaster from 'toasted-notes'
import 'toasted-notes/src/styles.css';
//const API_URL = 'http://localhost/vkEng/';
const API_URL = 'https://vkmachineries.com/api/';

const Contact = () => {
    const initialFormState = {name: '', email: '', number: '', message: '', error : {}};
    const [data, setData] = useState(initialFormState);

    const loader = {submitLoading:false};
    const [load, setLoad] = useState(loader);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setData({...data, [name]:value});
    }

    const validateData = (data) => {
        const errors = {};

        if(data.name.length <= 0){
            errors.name = "Name cannot be empty"
        }

        if(data.message.length <= 0){
            errors.message = "Message cannot be empty"
        }

        if(data.email.toString().trim().length <= 0){
            errors.email = "Email cannot be empty";
        }else if(data.email.toString().trim().length > 0 &&  !Validator.isEmail(data.email.toString().trim())){
            errors.email = "Invalid Email";
        }

        if(data.number === ''){
            errors.number = "Mobile number cannot be empty";
        }else if(data.number.toString().trim().length !== 10){
            errors.number ='Mobile number should be 10 digits';
        }

        return errors;
    }

    const toastMessage = () => {
        toaster.notify(<div style={{color:'green'}}>
            Your query submitted successfully ! Our team will contact you shortly
        </div>,{duration:2000});
        setLoad({...load, submitLoading:false});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let errors = validateData(data);
        setData({...data, error:errors});
        if(!data.name || !data.number || !data.email || !data.message) {
            return;
        } else if(data.number.length < 10 || !Validator.isEmail(data.email.toString().trim())) {
            return;
        }
        setLoad({...load, submitLoading:true});
        axios.post(API_URL+'sendMail', { data } )
        .then((response) => {
            toastMessage();
        })
        .catch((error) => {
            console.log('error block called',error);
        })
        setData(initialFormState);
    }

    return(
        <Layout className="section landing-section" style={{backgroundColor:'black'}}>
            {load.submitLoading &&
                <div className="loading">
                </div>
            }
            <Container>
                <Row>
                    <Col md="12">
                        <Row className="py-5 contact-wrap">
                            <Col md="6" className="pr-5">
                                <h2><span>Contact</span> Information</h2>
                                <p className="pt-4 blue">Contact us for your New & Old machines sales and service enquiries.<br/> We will help you to solve your machineries problems.</p>
                                <Row className="pt-5 pr-5">
                                    <Col md="2" className="pb-3 text-center">
                                        <img src="/images/vk-phone.png" width="58" alt="Phone" />
                                    </Col>
                                    <Col md="10" className="py-1">
                                        <p>+91 98422 21182</p>
                                        <p>+91 90470 13692, +91 90470 13693</p>
                                    </Col>
                                    <Col md="2" className="pb-3 text-center">
                                        <img src="/images/vk-email.png" width="58"  alt="Email" />
                                    </Col>
                                    <Col md="10" className="py-3">
                                        <p><a href="mailto:vknatarajan2010@gmail.com">vknatarajan2010@gmail.com</a></p>
                                    </Col>
                                    <Col md="2" className="pb-3 text-center">
                                        <img src="/images/vk-address.png" width="58" alt="Address" />
                                    </Col>
                                    <Col md="10" className="py-3">
                                        <p>No. 481/2, Ellaithottam Road, V.O.C. Colony East,
                                        Peelamedu, Coimbatore - 641 004</p>
                                    </Col>
                                </Row>

                            </Col>
                            <Col md="6">
                                <h2><span>Get in</span> touch</h2>
                                <p className="pt-4 blue">Submit your queries regarding New & Old machines sales and service related. <br/>We will assist to you.</p>
                                <Form className="contact-form"
                                    onSubmit={(e) => handleSubmit(e)}>
                                    <Row>
                                        <Col md="12">
                                            <label>Name</label>
                                            <InputGroup>
                                                <Input name="name" placeholder="Enter Your Name" type="text" value={data.name} onChange={handleChange}/>
                                            </InputGroup>
                                            {data.error.name && <span className="error">{data.error.name}</span>}
                                        </Col>

                                        <Col md="6">
                                            <label>Phone</label>
                                            <InputGroup>
                                                <Input name="number" placeholder="Enter Your Mobile Number" type="number" value={data.number} onChange={handleChange}/>
                                            </InputGroup>
                                            {data.error.number && <span className="error">{data.error.number}</span>}
                                        </Col>
                                        <Col md="6">
                                            <label>Email</label>
                                            <InputGroup>
                                                <Input name="email" placeholder="Enter Your Email ID" type="text" value={data.email} onChange={handleChange}/>
                                            </InputGroup>
                                            {data.error.email && <span className="error">{data.error.email}</span>}
                                        </Col>
                                    </Row>
                                    <label>Message</label>
                                    <Input
                                        name="message"
                                        placeholder="Submit your queries"
                                        type="textarea"
                                        rows="2"
                                        value={data.message}
                                        onChange={handleChange}
                                    />
                                    {data.error.message && <span className="error">{data.error.message}</span>}
                                    <Row>
                                        <Col className="ml-auto text-right" md="4">
                                            <Button className="btn-primary" type="submit">
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <div className="map-wrap">

                <embed
                    src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d14364.331684073582!2d77.00938815544697!3d11.03087070909121!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3ba8593fa07a5ee9%3A0x35ea8b777f743328!2sVEERAKUMAR+INDUSTRIAL+MACHINERIES!3m2!1d11.030210799999999!2d77.00975059999999!5e0!3m2!1sen!2sin!4v1563971598049!5m2!1sen!2sin"
                    width="100%"
                    height="450" />
                </div>
            </Layout>
        )
    }

    export default Contact;
